<template>
  <component :is="tag" class="blok-values parent-bg">
    <div class="section-inner">
      <h2 v-if="blok.title" class="title size-xl align-center">
        {{ blok.title }}
      </h2>
      <div v-if="text?.length" class="heading size-s" v-html="text"></div>
      <div
        v-if="isDesktop && blok.cards?.length && blok.cards?.length < 4"
        :class="`blok-values-cards ${blok.title || text?.length ? ' margin-top' : ''}`"
      >
        <StoryblokComponent
          v-for="item in blok.cards"
          :key="item._uid"
          :blok="item"
        />
      </div>
      <div
        v-if="(!isDesktop && blok.cards?.length) || blok.cards?.length > 3"
        :class="`carousel-container ${blok.title || text?.length ? ' margin-top' : ''}`"
      >
        <GenericCarousel
          v-if="blok.cards?.length > 1"
          :items="blok.cards"
          slides-per-view="auto"
          :space-between="14"
          class="carousel-navigation-bottom"
        />
      </div>
      <SimpleButton
        v-if="blok.cta_link?.url || blok.cta_link?.story?.url"
        :link="blok.cta_link"
        class="margin-top blok-values-cta"
        xl
      >
        {{ blok.cta_label }}
      </SimpleButton>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const { text } = useRichText(props.blok);
const isDesktop = inject("isDesktop");
</script>

<style lang="scss" scoped>
.heading {
  max-width: 56.25rem;
  margin: 0 auto;
}
.title {
  margin-bottom: 0.75rem;
}
.blok-values {
  @include pair-7;
  &-cards {
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-gap: 0.83rem;
    @include for-tablet-landscape-up {
      flex-wrap: wrap;
      :deep(.editorial-attribute) {
        min-width: auto;
        grid-column: span 6;
      }
    }
  }

  :deep(.editorial-attribute) {
    @include pair-1;
  }

  &-cta {
    margin-left: auto;
    margin-right: auto;
  }
}

@include for-tablet-landscape-up {
  .carousel-container {
    :deep(.editorial-attribute) {
      min-width: 22rem;
    }
  }
}
.alternate .blok-values {
  @include pair-1;
}
</style>

<style lang="scss">
.alternate .blok-values {
  .swiper-button-prev,
  .swiper-button-next {
    &::before {
      background-color: var(--solid-01);
    }
  }
}
</style>
